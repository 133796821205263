@use 'styles/config' as *;
@use "sass:math";

.articles {
  &__segment {
    @include segment();
  }

  &__container {
    @include container();
  }

  &__layout {
    .simple & {
      display: grid;
      column-gap: 6rem;
      row-gap: 6rem;
      justify-content: space-between;
      grid-template-columns: 1fr;

      @media (min-width: $min-960) {
        grid-template-columns: repeat(2, minmax(0, 51.7rem));
      }
    }
  }

  &__row {
    .default & {
      @include grid-row();
    }
  }

  &__col {
    .default & {
      @include grid-col();
    }
  }

  &__header {
    :global(body.dark) & {
      color: #fff;
    }

    .default & {
      @include grid-col();

      @media (min-width: $min-600) {
        @include grid-col(8);
      }

      @media (min-width: $min-720) {
        @include grid-col(8);
      }

      @media (min-width: $min-960) {
        @include grid-col(8);
        @include grid-offset-left(2);
        display: flex;
        flex-flow: column;
        align-items: center;
        text-align: center;
      }

      @media (min-width: $min-1280) {
        @include grid-col(6);
        @include grid-offset-left(3);
      }
    }
  }

  &__title {
    @include h2();

    .simple & {
      margin-bottom: 0.7em;
      margin-top: -0.1em;
      max-width: 100%;
    }

    .default & {
      margin-bottom: 1em;
      max-width: 100%;
    }
  }

  &__text {
    @include render-copy;

    .simple & {
      max-width: 40rem;

      @media (min-width: $min-600) {
        margin-left: responsive(3rem, 10.8rem);
      }

      @media (min-width: $min-960) {
        margin-bottom: 5.5rem;
      }
    }

    .default & {
      max-width: 40rem;
      margin-bottom: 4rem;

      @media (min-width: $min-960) {
        margin-bottom: 8rem;
      }
    }
  }

  &__button {
    .simple & {
      @media (max-width: $max-960) {
        display: none;
      }
    }

    .default & {
      margin-bottom: 9rem;
    }
  }

  &__list {
    .simple & {
      display: flex;
      flex-direction: column;
      gap: 6rem;
    }

    .default & {
      @include grid-row();

      @media (min-width: $min-600) {
        @include grid-row(rtl, stretch, flex-start, $gutter * 2);
      }

      @media (min-width: $min-960) {
        @include grid-row(ltr, stretch, flex-start, $gutter * 2);
      }
    }
  }

  &__footer {
    .simple & {
      @media (min-width: $min-960) {
        display: none;
      }
    }
  }
}

.item {
  @include reset-anchor();
  display: block;

  .default & {
    @include grid-col();
    margin-bottom: 4rem;
    direction: ltr;

    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: $min-600) {
      @include grid-col(6, flex-start, $gutter * 2);
    }

    @media (min-width: $min-960) {
      @include grid-col(4, flex-start, $gutter * 2);
      margin-bottom: 0;
    }
  }

  &__image {
    position: relative;
    margin-bottom: 4rem;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      mix-blend-mode: darken;
      box-shadow: inset 0 0 15rem rgba(#000, 0.03);
    }
  }

  &__aspect {
    width: 100%;
    height: 0;
    padding-bottom: 70%;
  }

  &__img {
    position: absolute 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition-property: transform;
    transition-duration: 1400ms;
  }

  &:focus &__img,
  &:hover &__img {
    transform: scale(1.15);
    transition-duration: 600ms;
  }

  .default &:first-child,
  .default &:nth-child(3) {
    @media (min-width: $min-600) {
      margin-top: -17rem;
    }

    @media (min-width: $min-960) {
      margin-top: -17rem;
    }

    @media (min-width: $min-1080) {
      margin-top: -19rem;
    }
  }

  .default &:nth-child(2) {
    @media (min-width: $min-600) {
      transform: translateY(4rem);
    }

    @media (min-width: $min-960) {
      transform: translateY(0);
    }
  }

  .default &:first-child &__aspect,
  .default &:nth-child(3) &__aspect {
    @media (min-width: $min-960) {
      padding-bottom: 90%;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
  }

  &__header {
    display: flex;
  }

  &__tagline {
    @include tagline;
    @include trailingLine;
    margin-bottom: 1.2rem;
  }

  &__title {
    @include h3();
    // max-width: 26rem;
    // margin-bottom: 2rem;
    letter-spacing: math.div(-0.93, 28) * 1em;

    transition-property: color, border;
    transition-duration: $transition-leave;

    :global(body.dark) & {
      color: #fff;
    }
  }

  &:focus &__title,
  &:hover &__title {
    @include brandColor(color);

    transition-property: color, border;
    transition-duration: $transition-enter;
  }

  &__date {
    @include tagline;
    margin-left: 1.5rem;
    margin-bottom: 1rem;
  }
}
